import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './WareHouseForm.scss';
import requestInstance from '../../../services/AuthService/AccessTokenUpdateSevice';
import { BASE_URL } from '../../../api/constants';
import AsyncSelect from 'react-select/async';
import ImageForm from '../../../components/ImageForm/ImageForm';
import { TeamList } from '../TeamList/TeamList';


export const WareHouseForm = ({ setCurrentForm,  wareHouseId, setCurrentTeamMember,  }) => {
  const [wareHouse, setWareHouse] = useState({
    company: JSON.parse(localStorage.getItem('user')).company.id,
    name: "",
    description: "",
    address: "",
    address_2: "",
    zip_code: "",
    country: "",
    type_of_ware_house: null,
    // facebook_url: "",
    // instagram_url: "",
    // you_tube_url: "",
    // logo: null  
  });

  const [warehouses, setWarehouses] = useState([]);
  const [types, setTypes] = useState([]);

  useEffect(() => {
    console.log(warehouses)
    const fetchData = async () => {
      try {
        const warehousesUrl = `${BASE_URL}ware-houses/v2/?company_id=${wareHouse.company}`;
        const typesUrl = `${BASE_URL}ware-houses/type/`;
  
        const [warehousesResponse, typesResponse] = await Promise.all([
          requestInstance.get(warehousesUrl),
          requestInstance.get(typesUrl)
        ]);
  
        setWarehouses(warehousesResponse.data.results);
        setTypes([{
          "id": 1,
          "name": "Own wearhouse",
        },
        {
          "id": 2,
          "name": "Distributor warehouse",
      }]);
        console.log(typesResponse.data.results)
  
        if (wareHouseId) {
          const wareHouseUrl = `${BASE_URL}ware-houses/v2/${wareHouseId}/`;
          const wareHouseResponse = await requestInstance.get(wareHouseUrl);
          const wareHouseData = wareHouseResponse.data;
          setWareHouse({
            ...wareHouseData,
            company: wareHouseData.company.id,
            type_of_ware_house: wareHouseData.type_of_ware_house.id,
          });
        }
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchData();
  }, [wareHouseId, wareHouse.company]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setWareHouse({ ...wareHouse, [name]: files[0] });
    } else {
      setWareHouse({ ...wareHouse, [name]: value });
    }
  };

  const filterEmptyValues = (obj) => {
    const filtered = {};
    for (const key in obj) {
      if (obj[key] !== "" && obj[key] !== null && obj[key] !== undefined) {
        filtered[key] = obj[key];
      }
    }
    console.log(filtered)
    return filtered;
  };

  const handleSave = async () => {
    try {
      const filteredData = filterEmptyValues(wareHouse);
      const formData = new FormData();
      for (const key in filteredData) {
        if (key === 'logo' && typeof filteredData[key] === 'string') {
          continue;
        }
        formData.append(key, filteredData[key]);
      }
  
      if (wareHouseId) {
        formData.set('company', wareHouse.company);
      }
  
      const url = wareHouseId ? `${BASE_URL}ware-houses/v2/${wareHouseId}/` : `${BASE_URL}ware-houses/v2/`;
      const method = wareHouseId ? 'patch' : 'post';
  
      const headers = {
        'Content-Type': 'multipart/form-data'
      };
  
      const config = {
        method,
        url,
        data: formData,
        headers
      };
  
      const response = await requestInstance(config);
      console.log(response)
      toast.success('WareHouse saved successfully!');
      setCurrentForm('warehouses')
      if (wareHouseId) {
        const wareHouseResponse = await requestInstance.get(`${BASE_URL}ware-houses/v2/${wareHouseId}/`);
        const wareHouseData = wareHouseResponse.data;
        setWareHouse({
          ...wareHouseData,
          company: wareHouseData.company.id,
          type_of_ware_house: wareHouseData.type_of_ware_house.id,
        });
      }
    } catch (error) {
      console.error(error);
      if (error.response && error.response.status === 400 && error.response.data) {
        const errorMessages = error.response.data;
        for (const field in errorMessages) {
          if (errorMessages.hasOwnProperty(field)) {
            errorMessages[field].forEach(message => {
              toast.error(`${field}: ${message}`);
            });
          }
        }
      } else {
        toast.error('Failed to save wareHouse.');
      }
    } 
  };

  const loadTypes = async (inputValue, callback) => {
    const filteredOptions = types.filter(type =>
      type.name.toLowerCase().includes(inputValue.toLowerCase())
    ).map(type => ({ value: type.id, label: type.name }));
    callback(filteredOptions);
  };

  return (
    <div className='ProductForm'>
      <ToastContainer />
      <div className='ProductForm__header'>
        <h2 className='ProductForm__title'>{wareHouseId ? 'Update WareHouse' : 'Create WareHouse'}</h2>
        <div className='ProductForm__header__buttons'>
          <button className='ProductForm__header__button ProductForm__header__button--cancel' onClick={() => setCurrentForm('warehouses')}>Back</button>
          <button className='ProductForm__header__button ProductForm__header__button--save' onClick={handleSave}>Save</button>
        </div>
      </div>
      <div className='ProductForm__section'>
        {/* {wareHouseId && <ImageForm objectId={wareHouseId} objectType='wareHouse' /> } */}
        <div className='ProductForm__row'>
          <div className='ProductForm__field'>
            <h3 className='ProductForm__field_title'>Name</h3>
            <input className='ProductForm__input' name='name' value={wareHouse.name} onChange={handleChange} />
          </div>
          <div className='ProductForm__field'>
            <h3 className='ProductForm__field_title'>Type of WareHouse</h3>
            <div className='CompanyForm__select-wrapper'>
            {/* <AsyncSelect
              cacheOptions
              loadOptions={loadTypes}
              defaultOptions={types.map(type => ({ value: type.id, label: type.name }))}
              onChange={(selectedOption) => setWareHouse({ ...wareHouse, type_of_ware_house: selectedOption.value })}
              value={wareHouse.type_of_ware_house ? { value: wareHouse.type_of_ware_house, label: types.find(t => t.id === wareHouse.type_of_ware_house)?.name } : null}
            /> */}
            <AsyncSelect
  name="type_of_ware_house"
  value={
    wareHouse.type_of_ware_house
      ? { value: wareHouse.type_of_ware_house, label: wareHouse.type_of_ware_house }
      : null
  }
  defaultOptions={[
    { value: "Own warehouse", label: "Own warehouse" },
    { value: "Distributor warehouse", label: "Distributor warehouse" },
  ]}
  loadOptions={(inputValue, callback) => {
    const options = [
      { value: "Own warehouse", label: "Own warehouse" },
      { value: "Distributor warehouse", label: "Distributor warehouse" },
    ];
    const filteredOptions = options.filter((option) =>
      option.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    callback(filteredOptions);
  }}
  onChange={(selectedOption) =>
    setWareHouse({ ...wareHouse, type_of_ware_house: selectedOption.value })
  }
/>
            </div>
          </div>
        </div>
        <div className='ProductForm__row'>
          <div className='ProductForm__field'>
            <h3 className='ProductForm__field_title'>Address</h3>
            <input className='ProductForm__input' name='address' value={wareHouse.address} onChange={handleChange} />
          </div>
          <div className='ProductForm__field'>
            <h3 className='ProductForm__field_title'>Address 2</h3>
            <input className='ProductForm__input' name='address_2' value={wareHouse.address_2} onChange={handleChange} />
          </div>
        </div>
        <div className='ProductForm__row'>
          <div className='ProductForm__field'>
            <h3 className='ProductForm__field_title'>Zip Code</h3>
            <input className='ProductForm__input' name='zip_code' value={wareHouse.zip_code} onChange={handleChange} />
          </div>
          <div className='ProductForm__field'>
            <h3 className='ProductForm__field_title'>Country</h3>
            <input className='ProductForm__input' name='country' value={wareHouse.country} onChange={handleChange} />
          </div>
        </div>
        <div className='ProductForm__row ProductForm__field--description'>
          <div className='ProductForm__field'>
            <h3 className='ProductForm__field_title'>Description</h3>
            <textarea className='ProductForm__input' name='description' value={wareHouse.description} onChange={handleChange} />
          </div>
        </div>
        {/* <div className='ProductForm__row'>
          <div className='ProductForm__field ProductForm__field--long'>
            <h3 className='ProductForm__field_title'>Facebook URL</h3>
            <input className='ProductForm__input' name='facebook_url' value={wareHouse.facebook_url} onChange={handleChange} />
          </div>
        </div>
        <div className='ProductForm__row' >
          <div className='ProductForm__field ProductForm__field--long'>
            <h3 className='ProductForm__field_title'>Instagram URL</h3>
            <input className='ProductForm__input' name='instagram_url' value={wareHouse.instagram_url} onChange={handleChange} />
          </div>
        </div>
        <div className='ProductForm__row'>
          <div className='ProductForm__field ProductForm__field--long'>
            <h3 className='ProductForm__field_title'>YouTube URL</h3>
            <input className='ProductForm__input' name='you_tube_url' value={wareHouse.you_tube_url} onChange={handleChange} />
          </div>
        </div>
        <div className='ProductForm__row'>
          <div className='ProductForm__field ProductForm__field--long'>
            <h3 className='ProductForm__field_title'>Logo</h3>
            <input className='ProductForm__input' type='file' name='logo' onChange={handleChange} />
          </div>
        </div> */}
        {/* {wareHouseId && <TeamList wareHouseId={wareHouseId} setCurrentTeamMember={setCurrentTeamMember } setCurrentForm={setCurrentForm} /> } */}
      </div>
    </div>
  );
};

export default WareHouseForm;