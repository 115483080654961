import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ProfileSideBar } from './ProfileSideBar/ProfileSideBar';
import { CompanyForm } from './CompanyForm/CompanyForm';
import { ProductsList } from './ProductsList/ProductsList';
import { ProductForm } from './ProductForm/ProductForm';
import { PasswordForm } from './PasswordForm/PasswordForm'
import { WareHouseList } from './WareHouseList/WareHouseList';
import { WareHouseForm } from './WareHouseForm/WareHouseForm';
import { TeamMemberForm } from './TeamMemberForm/TeamMemberForm';
import { CocktailsList } from './CocktailsList/CocktailsList';
import { CocktailForm } from './CocktailForm/CocktailForm';
import { Billing } from './Billing/Billing';
import { SubscribtionProfile } from './SubscribtionProfile/SubscribtionProfile'
import { BrandList } from './BrandList/BrandList'
import {BrandForm} from './BrnadForm/BrandForm'

export const ProfilePage = () => {
  const location = useLocation();
  const initialForm = location.state?.currentForm || 'company';
  
  
  const [currentForm, setCurrentForm] = useState(initialForm);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [currentWareHouse, setCurrentWareHouse] = useState(null);
  const [currentBrand, setCurrentBrand] = useState(null);
  const [currentTeamMember, setCurrentTeamMember] = useState(null);
  const [currentCocktail, setCurrentCocktail] = useState(null)

  useEffect(() => {
    if (location.state?.productId) {
      setCurrentProduct(location.state.productId);
    }
  }, [location.state]);

  return (
    <div style={{ display: 'flex' }}>
      <ProfileSideBar setCurrentForm={setCurrentForm} currentForm={currentForm} />
      {currentForm === 'company' && <CompanyForm />}
      
      {currentForm === 'products' && (
        <ProductsList
          setCurrentForm={(item) => setCurrentForm(item)}
          setCurrentProduct={setCurrentProduct}
        />
      )}
      {currentForm === 'productForm' && <ProductForm setCurrentForm={(item) => setCurrentForm(item)} productId={currentProduct} />}
      {currentForm === 'password' && <PasswordForm />}
      {currentForm === 'warehouses' && (
        <WareHouseList
          setCurrentForm={(item) => setCurrentForm(item)}
          setCurrentProduct={setCurrentWareHouse}
        />
      )}
      {currentForm === 'warehouseForm' && (<WareHouseForm setCurrentForm={(item) => setCurrentForm(item)} wareHouseId={currentWareHouse} setCurrentTeamMember={setCurrentTeamMember } />
      )}
      {currentForm === 'teamMemberForm' && (<TeamMemberForm setCurrentForm={(item) => setCurrentForm(item)} wareHouseId={currentBrand} teamMemberId={currentTeamMember} setCurrentTeamMember={setCurrentTeamMember} />
      )}
      {currentForm === 'brands' && (<BrandList
        setCurrentForm={(item) => setCurrentForm(item)}
        setCurrentProduct={setCurrentBrand}
      />)}
      {currentForm === 'brandForm' && (<BrandForm setCurrentForm={(item) => setCurrentForm(item)} wareHouseId={currentBrand} setCurrentTeamMember={setCurrentTeamMember } />
      )}
      {currentForm === 'cocktails' && (<CocktailsList setCurrentForm={(item) => setCurrentForm(item)} setCurrentCocktail={setCurrentCocktail} />
      )}
      {currentForm === 'cocktailForm' && (<CocktailForm setCurrentForm={(item) => setCurrentForm(item)} cocktailId={currentCocktail} />
      )}
      {currentForm === 'billing' && (<Billing />)}   
      {currentForm === 'subscription' && (<SubscribtionProfile />)}
    </div>
  );
};

export default ProfilePage;
