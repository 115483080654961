import './TeamMemberForm.scss';
import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { formatISO } from 'date-fns';
import requestInstance from '../../../services/AuthService/AccessTokenUpdateSevice';
import { BASE_URL } from '../../../api/constants';


export const TeamMemberForm = ({ wareHouseId, setCurrentForm, teamMemberId, setCurrentTeamMember }) => {
  const initialState = {
    full_name: "",
    position: "",
    joined_at: formatISO(new Date(), { representation: 'complete' }),
    description: "",
    warehouse: wareHouseId,
    photo: null
  };
  console.log(wareHouseId)

  const [teamMember, setTeamMember] = useState(initialState);

  useEffect(() => {
    if (teamMemberId) {
      const fetchTeamMember = async () => {
        try {
          const teamMemberUrl = `${BASE_URL}ware-houses/team-member/${teamMemberId}/`;
          const response = await requestInstance.get(teamMemberUrl);
          const memberData = response.data;
          setTeamMember({
            ...memberData,
            warehouse: memberData.warehouse.id,
            joined_at: memberData.joined_at ? formatISO(new Date(memberData.joined_at), { representation: 'complete' }) : formatISO(new Date(), { representation: 'complete' }),
          });
          setCurrentTeamMember(memberData.id)
        } catch (error) {
          console.error(error);
        }
      };

      fetchTeamMember();
    } else {
      setCurrentTeamMember(null); 
    }
  }, [teamMemberId, wareHouseId, setCurrentTeamMember]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setTeamMember({ ...teamMember, [name]: files[0] });
    } else {
      setTeamMember({ ...teamMember, [name]: value });
    }
  };

  const handleSave = async () => {
    try {
      const formData = new FormData();
      for (const key in teamMember) {
        if (teamMember[key] !== null && teamMember[key] !== undefined) {
          if (key === 'photo' && typeof teamMember[key] === 'string') {
            continue; 
          }
          formData.append(key, teamMember[key]);
        }
      }

      const url = teamMemberId ? `${BASE_URL}ware-houses/team-member/${teamMemberId}/` : `${BASE_URL}ware-houses/team-member/`;
      const method = teamMemberId ? 'patch' : 'post';

      const headers = {
        'Content-Type': 'multipart/form-data'
      };

      const config = {
        method,
        url,
        data: formData,
        headers
      };

      await requestInstance(config);

      toast.success('Team Member saved successfully!');
      setCurrentTeamMember(null)
      setCurrentForm('brandForm'); 
    } catch (error) {
      console.error(error);
      if (error.response && error.response.status === 400 && error.response.data) {
        const errorMessages = error.response.data;
        for (const field in errorMessages) {
          if (errorMessages.hasOwnProperty(field)) {
            errorMessages[field].forEach(message => {
              toast.error(`${field}: ${message}`);
            });
          }
        }
      } else {
        toast.error('Failed to save Team Member.');
      }
    }
  };

  return (
    <div className='TeamMemberForm'>
      <ToastContainer />
      <div className='TeamMemberForm__header'>
        <h2 className='TeamMemberForm__title'>{teamMemberId ? 'Update Team Member' : 'Create Team Member'}</h2>
        <div className='TeamMemberForm__header__buttons'>
          <button className='TeamMemberForm__header__button TeamMemberForm__header__button--cancel' onClick={() => setCurrentForm('brandForm')}>Back</button>
          <button className='TeamMemberForm__header__button TeamMemberForm__header__button--save' onClick={handleSave}>Save</button>
        </div>
      </div>
      <div className='TeamMemberForm__section'>
        <div className='TeamMemberForm__row'>
          <div className='TeamMemberForm__field'>
            <h3 className='TeamMemberForm__field_title'>Full Name</h3>
            <input className='TeamMemberForm__input' name='full_name' value={teamMember.full_name} onChange={handleChange} />
          </div>
          <div className='TeamMemberForm__field'>
            <h3 className='TeamMemberForm__field_title'>Position</h3>
            <input className='TeamMemberForm__input' name='position' value={teamMember.position} onChange={handleChange} />
          </div>
        </div>
        <div className='TeamMemberForm__row'>
          <div className='TeamMemberForm__field'>
            <h3 className='TeamMemberForm__field_title'>Joined At</h3>
            <input className='TeamMemberForm__input' type='datetime-local' name='joined_at' value={teamMember.joined_at.slice(0, 16)} onChange={handleChange} />
          </div>
          <div className='TeamMemberForm__field'>
            <h3 className='TeamMemberForm__field_title'>Description</h3>
            <textarea className='TeamMemberForm__input' name='description' value={teamMember.description} onChange={handleChange} />
          </div>
        </div>
        <div className='TeamMemberForm__row'>
          <div className='TeamMemberForm__field'>
            <h3 className='TeamMemberForm__field_title'>Photo</h3>
            <input className='TeamMemberForm__input' type='file' name='photo' onChange={handleChange} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamMemberForm;
