import './CompanyForm.scss';
import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import { getNames } from 'country-list';

import { BASE_URL } from '../../../api/constants';
import { requestInstance } from '../../../services/AuthService/AccessTokenUpdateSevice';

export const CompanyForm = ({
  setIsUserFormVisible,
  setIsRegisterFormVisible,
  setIsLoginFormVisible
}) => {
  const [formData, setFormData] = useState({
    type_of_business: 'brand',
    first_name: '',
    last_name: '',
    email: '',
    brand_name: '',
    phone: '+',
    country: '',
    language: 'English',
    company_size: 'small',
    primary_interests: 'brand owner',
  });
  const [isChecked, setIsChecked] = useState(false);
  const [errors, setErrors] = useState({});
  const [isOtherSelected, setIsOtherSelected] = useState(false); // Для отображения поля ввода
  const [otherInputValue, setOtherInputValue] = useState(''); // Значение пользовательского ввода

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'primary_interests') {
      if (value === 'other') {
        setIsOtherSelected(true);
        setFormData({ ...formData, primary_interests: '' }); // Очищаем основное поле
      } else {
        setIsOtherSelected(false);
        setFormData({ ...formData, primary_interests: value }); // Обновляем обычное значение
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
  const handleOtherInputChange = (e) => {
    const value = e.target.value;
    setOtherInputValue(value);
    setFormData({ ...formData, primary_interests: value }); // Обновляем `primary_interests` пользовательским значением
  };

  const getPrimaryInterestsOptions = () => {
    switch (formData.type_of_business) {
      case 'brand':
        return [
          { value: 'brand owner', label: 'Brand Owner' },
          { value: 'manufacturer', label: 'Manufacturer' },
          { value: 'brand representative', label: 'Brand Representative' },
          { value: 'other', label: 'Other: write in' },
        ];
      case 'buyer':
        return [
          { value: 'hotel', label: 'Hotel' },
          { value: 'restaurant', label: 'Restaurant' },
          { value: 'bar', label: 'Bar' },
          { value: 'club', label: 'Club' },
          { value: 'event manager', label: 'Event Manager' },
          { value: 'grocery store', label: 'Grocery Store' },
          { value: 'liquor store', label: 'Liquor Store' },
          { value: 'other retailer', label: 'Other Retailer' },
          { value: 'cruise line', label: 'Cruise Line' },
          { value: 'other', label: 'Other: write in' },
        ];
      case 'complementor':
        return [
          { value: 'designer', label: 'Designer' },
          { value: 'other', label: 'Other: write in' },
        ]; 
      default:
        return [];
    }
  };


  const handleFullNameChange = (e) => {
    setErrors({})
    const fullName = e.target.value.trim().split(' ');
    const firstName = fullName[0] || '';
    const lastName = fullName.slice(1).join(' ') || '';
    setFormData({ ...formData, first_name: firstName, last_name: lastName });
  };

  const allCountries = getNames().map((country) => ({
    value: country,
    label: country,
  }));

  const handleCountryChange = (selectedOption) => {
    setFormData({ ...formData, country: selectedOption ? selectedOption.value : '' });
  };

  const filterCountries = (inputValue) => {
    return allCountries.filter((country) =>
      country.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const loadCountryOptions = (inputValue, callback) => {
    setTimeout(() => {
      callback(filterCountries(inputValue));
    }, 1000); 
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const url = BASE_URL + 'auth/registration-1-step/';
      console.log(formData)
      const response = await requestInstance.post(url, formData);
      localStorage.setItem('companyID', response.data.id);
      localStorage.setItem('email', response.data.email);
      console.log('Form Data:', formData);
      console.log('Response:', response.data);

      setIsRegisterFormVisible(false)
      setIsUserFormVisible(true)
    } catch (error) {
      if (error.response && error.response.data) {
        setErrors(error.response.data);
      } else {
        console.error('Error submitting form:', error);
      }
    }
  };
  
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  return (
    <div className="SignUpForm">
      <h2 className="SignUpForm__title">
        Sign Up
        <span className='closeForm' onClick={() => setIsRegisterFormVisible(false)}>x</span>
      </h2>
      <form className="SignUpForm__form" onSubmit={handleSubmit}>
        <div className="SignUpForm__input-group">
          <label htmlFor="type_of_business">I am a...</label>
          <div className="select-wrapper">
            <select
              name="type_of_business"
              value={formData.type_of_business}
              onChange={handleChange}
            >
              <option value="brand">Brand</option>
              <option value="buyer">Buyer</option>
              <option value="complementor">Complementor</option>
            </select>
          </div>
        </div>
        <div className="SignUpForm__input-group">
          <label htmlFor="full_name">First name and last name</label>
          <input
            type="text"
            name="full_name"
            // value={`${formData.first_name} ${formData.last_name}`}
            onChange={handleFullNameChange}
            required
          />
          {(errors.first_name || errors.last_name) && <p className="ErrorText">Here should be your first and last name</p>}

        </div>
        <div className="SignUpForm__input-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
          {errors.email && <p className="ErrorText">{errors.email[0]}</p>}
        </div>

        <div className="SignUpForm__input-group__container">
          <div className="SignUpForm__input-group SignUpForm__input-group--half">
            <label htmlFor="company_name">Company name</label>
            <input
              type="text"
              name="brand_name"
              value={formData.brand_name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="SignUpForm__input-group SignUpForm__input-group--half">
            <label htmlFor="phone">Phone Number</label>
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
            {errors.phone && <p className="ErrorText">{errors.phone[0]}</p>}
          </div>
        </div>

        <div className="SignUpForm__input-group__container">
          <div className="SignUpForm__input-group SignUpForm__input-group--half Contry__select">
            <label htmlFor="country">Country</label>
            <AsyncSelect
              name="country"
              value={allCountries.find(
                (option) => option.value === formData.country
              )}
              onChange={handleCountryChange}
              loadOptions={loadCountryOptions}
              defaultOptions
              cacheOptions
              menuPlacement="auto"
              styles={{
                menu: (provided) => ({
                  ...provided,
                  maxHeight: '250px', 
                  overflowY: 'auto' 
                }),
              }}
              required
              />
          </div>
          <div className="SignUpForm__input-group SignUpForm__input-group--half">
            <label htmlFor="language">Language</label>
            <div className="select-wrapper">
            <select
              name="language"
              value={formData.language}
              onChange={handleChange}
              required
            >
              <option value='English'>English</option>
              <option value='German'>German</option>
              <option value='French'>French</option>
              <option value='Spanish'>Spanish</option>
              <option value='Italian'>Italian</option>
              <option value='Norwegian'>Norwegian</option>
            </select>
            </div>
          </div>
        </div>

        <div className="SignUpForm__input-group__container">
          <div className="SignUpForm__input-group SignUpForm__input-group--half">
            <label htmlFor="company_size">Company size</label>
            <div className="select-wrapper">
            <select
              name="company_size"
              value={formData.company_size}
              onChange={handleChange}
              required
            >
                <option value='small'>Small</option>
                <option value='medium'>Medium</option>
                <option value='large'>Large</option>
            </select>
            </div>
          </div>
          <div className="SignUpForm__input-group SignUpForm__input-group--half">
            <label htmlFor="primary_interests">Are you...</label>
            <div className="select-wrapper">
        <select
          name="primary_interests"
          value={isOtherSelected ? 'other' : formData.primary_interests}
          onChange={handleChange}
        >
          <option value="" disabled>
            Select an option
          </option>
          {getPrimaryInterestsOptions().map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      {/* </div> */}

            </div>
          </div>

        </div>

        {isOtherSelected && (
          <div className="SignUpForm__input-group">
            <label htmlFor="other_interest">Please specify:</label>
            <input
              type="text"
              name="other_interest"
              value={otherInputValue}
              onChange={handleOtherInputChange}
              required
            />
          </div>
        )}

        <div className="SignUpForm__privacy">
        <input
          type="checkbox"
          id="improve_experience"
          onChange={handleCheckboxChange}
        />
        <label htmlFor="improve_experience">
          I want to help improve the user experience by recording the start of my trial session 
          <a href="#how"> (how?)</a>
        </label>
      </div>
      
      <div className="SignUpForm__terms">
        <p>
          By clicking on <strong>Start Now</strong>, you accept our 
          <a href="#subscription"> Subscription Agreement</a> and 
          <a href="#privacy"> Privacy Policy</a>.
        </p>
      </div>

      <button
        type="submit"
        className="SignUpForm__submit"
      >
        Next step
      </button>
      
      </form>
      <div className="SignUpForm__footer">
        <p>
          Already have an account?
          <a href="#login" className="SignUpForm__login" onClick={() => {
            setIsLoginFormVisible(true)
            setIsRegisterFormVisible(false)
          }}> Log In</a>
        </p>
      </div>
    </div>
  );
};

export default CompanyForm;
