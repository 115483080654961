// import React, { useState, useEffect, useRef } from 'react';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import './WareHouseForm.scss';
// import requestInstance from '../../../services/AuthService/AccessTokenUpdateSevice';
// import { BASE_URL } from '../../../api/constants';
// import AsyncSelect from 'react-select/async';
// import ImageForm from '../../../components/ImageForm/ImageForm';
// import { TeamList } from '../TeamList/TeamList';


// export const BrandForm = ({ setCurrentForm,  wareHouseId, setCurrentTeamMember,  }) => {
//   const [wareHouse, setWareHouse] = useState({
//     company: JSON.parse(localStorage.getItem('user')).company.id,
//     name: "",
//     // description: "",
//     // address: "",
//     // address_2: "",
//     // zip_code: "",
//     // country: "",
//     // type_of_ware_house: null,
//     facebook_url: "",
//     instagram_url: "",
//     you_tube_url: "",
//     logo: null  
//   });
//   const initialProduct = {
//     company: JSON.parse(localStorage.getItem('user')).company.id,
//     name: "",
//     // description: "",
//     // address: "",
//     // address_2: "",
//     // zip_code: "",
//     // country: "",
//     // type_of_ware_house: null,
//     facebook_url: "",
//     instagram_url: "",
//     you_tube_url: "",
//     logo: null  
//   }
//   console.log(wareHouseId)
//   const [warehouses, setWarehouses] = useState([]);
//   const [types, setTypes] = useState([]);
//   const [productPicture, setProductPicture] = useState({});
//   const fileInputRef = useRef(null);

//   useEffect(() => {
//     console.log(warehouses)
//     const fetchData = async () => {
//       try {
//         const warehousesUrl = `${BASE_URL}ware-houses/?company_id=${wareHouse.company}`;
//         // const typesUrl = `${BASE_URL}ware-houses/type/`;
  
//         const [warehousesResponse, typesResponse] = await Promise.all([
//           requestInstance.get(warehousesUrl),
//           // requestInstance.get(typesUrl)
//         ]);
  
//         setWarehouses(warehousesResponse.data.results);
//         setTypes([{
//           "id": 1,
//           "name": "Own wearhouse",
//         },
//         {
//           "id": 2,
//           "name": "Distributor warehouse",
//       }]);
//         console.log(typesResponse.data.results)
  
//         if (wareHouseId) {
//           const wareHouseUrl = `${BASE_URL}ware-houses/${wareHouseId}/`;
//           const wareHouseResponse = await requestInstance.get(wareHouseUrl);
//           const wareHouseData = wareHouseResponse.data;
//           setWareHouse({
//             ...wareHouseData,
//             company: wareHouseData.company.id,
//             // type_of_ware_house: wareHouseData.type_of_ware_house.id,
//           });
//         }
//       } catch (error) {
//         console.error(error);
//       }
//     };
  
//     fetchData();
//   }, [wareHouseId, wareHouse.company]);

//   const handleChange = (e) => {
//     const { name, value, files } = e.target;
//     if (files) {
//       setWareHouse({ ...wareHouse, [name]: files[0] });
//     } else {
//       setWareHouse({ ...wareHouse, [name]: value });
//     }
//   };

//   const filterEmptyValues = (obj) => {
//     const filtered = {};
//     for (const key in obj) {
//       if (obj[key] !== "" && obj[key] !== null && obj[key] !== undefined) {
//         filtered[key] = obj[key];
//       }
//     }
//     console.log(filtered)
//     return filtered;
//   };

//   const handleSave = async () => {
//     try {
//       const filteredData = filterEmptyValues(wareHouse);
//       const formData = new FormData();
//       for (const key in filteredData) {
//         if (key === 'logo' && typeof filteredData[key] === 'string') {
//           continue;
//         }
//         formData.append(key, filteredData[key]);
//       }
  
//       if (wareHouseId) {
//         formData.set('company', wareHouse.company);
//       }
  
//       const url = wareHouseId ? `${BASE_URL}ware-houses/${wareHouseId}/` : `${BASE_URL}ware-houses/`;
//       const method = wareHouseId ? 'patch' : 'post';
  
//       const headers = {
//         'Content-Type': 'multipart/form-data'
//       };
  
//       const config = {
//         method,
//         url,
//         data: formData,
//         headers
//       };
  
//       const response = await requestInstance(config);
//       console.log(response)
//       toast.success('WareHouse saved successfully!');
//       setCurrentForm('brands')
//       if (wareHouseId) {
//         const wareHouseResponse = await requestInstance.get(`${BASE_URL}ware-houses/${wareHouseId}/`);
//         const wareHouseData = wareHouseResponse.data;
//         setWareHouse({
//           ...wareHouseData,
//           company: wareHouseData.company.id,
//           type_of_ware_house: wareHouseData.type_of_ware_house.id,
//         });
//       }
//       if (!wareHouseId && productPicture.picture) {
//         const formData = new FormData();
//         console.log(response.data)
//         formData.append('image', productPicture.picture);
//         formData.append('ware_house', response.data.id);
  
//         try {
//           formData.forEach((value, key) => {
//             console.log(`${key}:`, value);
//           });
//           const imageResponse = await requestInstance.post(
//             `${BASE_URL}ware-houses/images/`,
//             formData,
//             {
//               headers: {
//                 'Content-Type': 'multipart/form-data',
//               },
//             }
//           );
//           if (fileInputRef.current) {
//             fileInputRef.current.value = '';
//           }
//           console.log(imageResponse.data);
//         } catch (imageError) {
//           console.error(imageError);
//           toast.error('Failed to upload image.');
//         }
//       }
//       setWareHouse(initialProduct);
//       setProductPicture({});
//     } catch (error) {
//       console.error(error);
//       if (error.response && error.response.status === 400 && error.response.data) {
//         const errorMessages = error.response.data;
//         for (const field in errorMessages) {
//           if (errorMessages.hasOwnProperty(field)) {
//             errorMessages[field].forEach(message => {
//               toast.error(`${field}: ${message}`);
//             });
//           }
//         }
//       } else {
//         toast.error('Failed to save wareHouse.');
//       }
//     } 
//   };

//   const loadTypes = async (inputValue, callback) => {
//     const filteredOptions = types.filter(type =>
//       type.name.toLowerCase().includes(inputValue.toLowerCase())
//     ).map(type => ({ value: type.id, label: type.name }));
//     callback(filteredOptions);
//   };
//   const handleFileChange = (e) => {
//     const { name, files } = e.target;
//     setProductPicture({ ...productPicture, [name]: files[0] });
//   };

//   return (
//     <div className='ProductForm'>
//       <ToastContainer />
//       <div className='ProductForm__header'>
//         <h2 className='ProductForm__title'>{wareHouseId ? 'Update Brand Profile' : 'Create Brand Profile'}</h2>
//         <div className='ProductForm__header__buttons'>
//           <button className='ProductForm__header__button ProductForm__header__button--cancel' onClick={() => setCurrentForm('brands')}>Back</button>
//           <button className='ProductForm__header__button ProductForm__header__button--save' onClick={handleSave}>Save</button>
//         </div>
//       </div>
//       <div className='ProductForm__section'>
//         {/* {wareHouseId && <ImageForm objectId={wareHouseId} objectType='wareHouse' />} */}
//         {wareHouseId ? <ImageForm objectId={wareHouseId} objectType='wareHouse' /> :

//           <div className='CocktailForm__row'>
//             <div className='CocktailForm__field'>
//               <h3 className='CocktailForm__field_title'>Picture</h3>
//               <input
//                 className='CocktailForm__input'
//                 type='file'
//                 name='picture'
//                 onChange={handleFileChange}
//                 ref={fileInputRef}
//               />
//             </div>
//           </div>
//         }
//         <div className='ProductForm__row'>
//           <div className='ProductForm__field'>
//             <h3 className='ProductForm__field_title'>Brandname</h3>
//             <input className='ProductForm__input' name='name' value={wareHouse.name} onChange={handleChange} />
//           </div>
//           {/* <div className='ProductForm__field'>
//             <h3 className='ProductForm__field_title'>Type of WareHouse</h3>
//             <div className='CompanyForm__select-wrapper'>
//             <AsyncSelect
//               cacheOptions
//               loadOptions={loadTypes}
//               defaultOptions={types.map(type => ({ value: type.id, label: type.name }))}
//               onChange={(selectedOption) => setWareHouse({ ...wareHouse, type_of_ware_house: selectedOption.value })}
//               value={wareHouse.type_of_ware_house ? { value: wareHouse.type_of_ware_house, label: types.find(t => t.id === wareHouse.type_of_ware_house)?.name } : null}
//             />
//             <AsyncSelect
//   name="type_of_ware_house"
//   value={
//     wareHouse.type_of_ware_house
//       ? { value: wareHouse.type_of_ware_house, label: wareHouse.type_of_ware_house }
//       : null
//   }
//   defaultOptions={[
//     { value: "Own warehouse", label: "Own warehouse" },
//     { value: "Distributor warehouse", label: "Distributor warehouse" },
//   ]}
//   loadOptions={(inputValue, callback) => {
//     const options = [
//       { value: "Own warehouse", label: "Own warehouse" },
//       { value: "Distributor warehouse", label: "Distributor warehouse" },
//     ];
//     const filteredOptions = options.filter((option) =>
//       option.label.toLowerCase().includes(inputValue.toLowerCase())
//     );
//     callback(filteredOptions);
//   }}
//   onChange={(selectedOption) =>
//     setWareHouse({ ...wareHouse, type_of_ware_house: selectedOption.value })
//   }
// />
//             </div>
//           </div> */}
//         </div>
//         {/* <div className='ProductForm__row'>
//           <div className='ProductForm__field'>
//             <h3 className='ProductForm__field_title'>Address</h3>
//             <input className='ProductForm__input' name='address' value={wareHouse.address} onChange={handleChange} />
//           </div>
//           <div className='ProductForm__field'>
//             <h3 className='ProductForm__field_title'>Address 2</h3>
//             <input className='ProductForm__input' name='address_2' value={wareHouse.address_2} onChange={handleChange} />
//           </div>
//         </div> */}
//         {/* <div className='ProductForm__row'>
//           <div className='ProductForm__field'>
//             <h3 className='ProductForm__field_title'>Zip Code</h3>
//             <input className='ProductForm__input' name='zip_code' value={wareHouse.zip_code} onChange={handleChange} />
//           </div>
//           <div className='ProductForm__field'>
//             <h3 className='ProductForm__field_title'>Country</h3>
//             <input className='ProductForm__input' name='country' value={wareHouse.country} onChange={handleChange} />
//           </div>
//         </div>
//         <div className='ProductForm__row ProductForm__field--description'>
//           <div className='ProductForm__field'>
//             <h3 className='ProductForm__field_title'>Description</h3>
//             <textarea className='ProductForm__input' name='description' value={wareHouse.description} onChange={handleChange} />
//           </div>
//         </div> */}
//         <div className='ProductForm__row'>
//           <div className='ProductForm__field ProductForm__field--long'>
//             <h3 className='ProductForm__field_title'>Facebook URL</h3>
//             <input className='ProductForm__input' name='facebook_url' value={wareHouse.facebook_url} onChange={handleChange} />
//           </div>
//         </div>
//         <div className='ProductForm__row' >
//           <div className='ProductForm__field ProductForm__field--long'>
//             <h3 className='ProductForm__field_title'>Instagram URL</h3>
//             <input className='ProductForm__input' name='instagram_url' value={wareHouse.instagram_url} onChange={handleChange} />
//           </div>
//         </div>
//         <div className='ProductForm__row'>
//           <div className='ProductForm__field ProductForm__field--long'>
//             <h3 className='ProductForm__field_title'>YouTube URL</h3>
//             <input className='ProductForm__input' name='you_tube_url' value={wareHouse.you_tube_url} onChange={handleChange} />
//           </div>
//         </div>
//         <div className='ProductForm__row'>
//           <div className='ProductForm__field ProductForm__field--long'>
//             <h3 className='ProductForm__field_title'>Logo</h3>
//             <input className='ProductForm__input' type='file' name='logo' onChange={handleChange} />
//           </div>
//         </div>
//         {wareHouseId && <TeamList wareHouseId={wareHouseId} setCurrentTeamMember={setCurrentTeamMember } setCurrentForm={setCurrentForm} /> }
//       </div>
//     </div>
//   );
// };

// export default BrandForm;

import React, { useState, useEffect, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './WareHouseForm.scss';
import requestInstance from '../../../services/AuthService/AccessTokenUpdateSevice';
import { BASE_URL } from '../../../api/constants';
import ImageForm from '../../../components/ImageForm/ImageForm';
import { TeamList } from '../TeamList/TeamList';
import ReactQuill from 'react-quill';

export const BrandForm = ({ setCurrentForm, wareHouseId, setCurrentTeamMember }) => {
  const [wareHouse, setWareHouse] = useState({
    company: JSON.parse(localStorage.getItem('user')).company.id,
    name: '',
    description: '',
    facebook_url: '',
    instagram_url: '',
    you_tube_url: '',
    logo: null,
  });
  const [loading, setLoading] = useState(true);
  const [productPicture, setProductPicture] = useState({});
  const fileInputRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (wareHouseId) {
          const wareHouseUrl = `${BASE_URL}ware-houses/${wareHouseId}/`;
          const wareHouseResponse = await requestInstance.get(wareHouseUrl);
          const wareHouseData = wareHouseResponse.data;
          setWareHouse({
            ...wareHouseData,
            company: wareHouseData.company.id,
          });
        }
      } catch (error) {
        console.error('Failed to fetch warehouse data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [wareHouseId]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setWareHouse({ ...wareHouse, [name]: files[0] });
    } else {
      setWareHouse({ ...wareHouse, [name]: value });
    }
  };

  const handleSave = async () => {
    try {
      const filteredData = Object.fromEntries(
        Object.entries(wareHouse).filter(([_, value]) => value !== null && value !== '')
      );

      const formData = new FormData();
      Object.entries(filteredData).forEach(([key, value]) => {
        if (key === 'logo' && typeof value === 'string') return;
        formData.append(key, value);
      });

      const url = wareHouseId
        ? `${BASE_URL}ware-houses/${wareHouseId}/`
        : `${BASE_URL}ware-houses/`;
      const method = wareHouseId ? 'patch' : 'post';

      const response = await requestInstance({
        method,
        url,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      toast.success('Brand saved successfully!');
      setCurrentForm('brands');

      if (!wareHouseId && productPicture.picture) {
        const imageFormData = new FormData();
        imageFormData.append('image', productPicture.picture);
        imageFormData.append('ware_house', response.data.id);

        await requestInstance.post(`${BASE_URL}ware-houses/images/`, imageFormData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
      }

      setWareHouse({
        company: JSON.parse(localStorage.getItem('user')).company.id,
        name: '',
        description: '',
        facebook_url: '',
        instagram_url: '',
        you_tube_url: '',
        logo: null,
      });
      setProductPicture({});
    } catch (error) {
      console.error('Failed to save brand:', error);
      toast.error('Failed to save brand. Please try again.');
    }
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setProductPicture({ ...productPicture, [name]: files[0] });
  };

  if (loading) {
    return <div>Loading...</div>;
  }
  const handleDescriptionChange = (value) => {
    setWareHouse({ ...wareHouse, description: value });
  };

  return (
    <div className="ProductForm">
      <ToastContainer />
      <div className="ProductForm__header">
        <h2 className="ProductForm__title">
          {wareHouseId ? 'Update Brand Profile' : 'Create Brand Profile'}
        </h2>
        <div className="ProductForm__header__buttons">
          <button
            className="ProductForm__header__button ProductForm__header__button--cancel"
            onClick={() => setCurrentForm('brands')}
          >
            Back
          </button>
          <button
            className="ProductForm__header__button ProductForm__header__button--save"
            onClick={handleSave}
          >
            Save
          </button>
        </div>
      </div>
      <div className="ProductForm__section">
        {wareHouseId ? (
          <ImageForm objectId={wareHouseId} objectType="wareHouse" />
        ) : (
          <div className="CocktailForm__row">
            <div className="CocktailForm__field">
              <h3 className="CocktailForm__field_title">Picture</h3>
              <input
                className="CocktailForm__input"
                type="file"
                name="picture"
                onChange={handleFileChange}
                ref={fileInputRef}
              />
            </div>
          </div>
        )}
        <div className="ProductForm__row">
          <div className="ProductForm__field">
            <h3 className="ProductForm__field_title">Brand Name</h3>
            <input
              className="ProductForm__input"
              name="name"
              value={wareHouse.name || ''}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className='ProductForm__row ProductForm__field--description'>
           <div className='ProductForm__field' >
             <h3 className='ProductForm__field_title' >Describe your Brand and tell your story</h3>
            {/* <textarea className='ProductForm__input' name='description' value={wareHouse.description} onChange={handleChange} /> */}
            <ReactQuill
              theme="snow"
              value={wareHouse.description}
              onChange={handleDescriptionChange}
              style={{ width: '87%', marginBottom: '40px'}}
            />
           </div>
         </div> 
        <div className="ProductForm__row">
          <div className="ProductForm__field ProductForm__field--long">
            <h3 className="ProductForm__field_title">Facebook URL</h3>
            <input
              className="ProductForm__input"
              name="facebook_url"
              value={wareHouse.facebook_url || ''}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="ProductForm__row">
          <div className="ProductForm__field ProductForm__field--long">
            <h3 className="ProductForm__field_title">Instagram URL</h3>
            <input
              className="ProductForm__input"
              name="instagram_url"
              value={wareHouse.instagram_url || ''}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="ProductForm__row">
          <div className="ProductForm__field ProductForm__field--long">
            <h3 className="ProductForm__field_title">YouTube URL</h3>
            <input
              className="ProductForm__input"
              name="you_tube_url"
              value={wareHouse.you_tube_url || ''}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="ProductForm__row">
          <div className="ProductForm__field ProductForm__field--long">
            <h3 className="ProductForm__field_title">Logo</h3>
            <input
              className="ProductForm__input"
              type="file"
              name="logo"
              onChange={handleChange}
            />
          </div>
        </div>
        {wareHouseId && (
          <TeamList
            wareHouseId={wareHouseId}
            setCurrentTeamMember={setCurrentTeamMember}
            setCurrentForm={setCurrentForm}
          />
        )}
      </div>
    </div>
  );
};

export default BrandForm;
