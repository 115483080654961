import { useEffect, useState } from 'react';
import './WareHousesBest.scss';
import { BASE_URL } from '../../../api/constants';
import { requestInstance } from '../../../services/AuthService/AccessTokenUpdateSevice';
import detail from '../WareHousesType/images/next.png';
import { EmptyPage } from '../../../components/EmptyPage/EmptyPage'
import {Link} from 'react-router-dom'

const LoadingIndicator = () => <div className='WareHousesBest__loading'></div>
export const WareHousesBest = () => {
  const [bestWareHouses, setBestWareHouses] = useState([]);
  const [nextPage, setNextPage] = useState(null);
  const [prevPage, setPrevPage] = useState(null);
  const [loading, setLoading] = useState(true); 
  const itemsPerPage = 3;

  useEffect(() => {
    const requestBestWareHouses = async (offset = 0) => {
      try {
        setLoading(true); 
        const url = `${BASE_URL}ware-houses/?ordering=-average_rating&limit=${itemsPerPage}&offset=${offset}`;
        const response = await requestInstance.get(url);
        setBestWareHouses(response.data.results);
        setNextPage(response.data.next);
        setPrevPage(response.data.previous);
      } catch (error) {
        console.error('Failed to fetch warehouses:', error);
      } finally {
        setLoading(false); 
      }
    };
    requestBestWareHouses();
  }, [itemsPerPage]);

  const mainPhoto = (item) => item.ware_house_image.find(img => img.main)?.image;

  const toNextPage = async () => {
    if (nextPage) {
      try {
        const response = await requestInstance.get(nextPage);
        setBestWareHouses(response.data.results);
        setNextPage(response.data.next);
        setPrevPage(response.data.previous);
      } catch (error) {
        console.error('Failed to fetch next page:', error);
      } finally {
      }
    }
  };

  const toPrevPage = async () => {
    if (prevPage) {
      try {
        const response = await requestInstance.get(prevPage);
        setBestWareHouses(response.data.results);
        setNextPage(response.data.next);
        setPrevPage(response.data.previous);
      } catch (error) {
        console.error('Failed to fetch previous page:', error);
      } finally {
      }
    }
  };

  return (
    <div className='BestResellers'>
      <div className='BestResellers__title'>Our best resellers</div>
      {loading ? (
        <LoadingIndicator /> 
      ) : bestWareHouses.length > 0 ? (
        <div className='BestResellers__container'>
          {prevPage && <button className='WareHousesFeed__button BestResellers_btn' onClick={toPrevPage} disabled={!prevPage}>‹</button>}
            {bestWareHouses.map((wareHouse) => (
             
            <div className='BestResellers__item' key={wareHouse.id}>
              <img className='BestResellers__image' src={mainPhoto(wareHouse)} alt={wareHouse.name} />
                {/* <div className='BestResellers__name'><Link style={{ textDecoration: 'none', color: 'black' }}to={`/ware-house/${wareHouse.id}`}>{wareHouse.name}</Link></div> */}
                <div className='BestResellers__details'>
                <div className='BestResellers__name'><Link style={{ textDecoration: 'none', color: 'black', marginTop: '10px' }}to={`/ware-house/${wareHouse.id}`}>{wareHouse.name}</Link></div>

                {/* <div className='BestResellers__description'>{wareHouse.description.length > 40 ? `${wareHouse.description.substring(0, 40)}...` : wareHouse.description}</div> */}
                <div className='BestResellers__icon'><Link to={`/ware-house/${wareHouse.id}`}><img style={{marginTop: "20px"}} src={detail} alt='detail' /></Link></div>
                </div>
                </div>
          ))}
          {nextPage && <button className='WareHousesFeed__button WareHousesFeed__button--right BestResellers_btn' onClick={toNextPage} disabled={!nextPage}>›</button>}
        </div>
      ) : (
        <EmptyPage text="No warehouses available!" />
      )}
    </div>
  );
}

export default WareHousesBest;