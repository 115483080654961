import './TeamList.scss';
import React, { useState, useEffect } from 'react';
import requestInstance from '../../../services/AuthService/AccessTokenUpdateSevice';
import { BASE_URL } from '../../../api/constants';
import deleteImg from './images/delete.png'; 
import editImg from './images/edit.png'; 
import { toast } from 'react-toastify';

export const TeamList = ({ wareHouseId, setCurrentForm, setCurrentTeamMember }) => {
  const [teamMembers, setTeamMembers] = useState([]);

  useEffect(() => {
    const fetchTeamMembers = async () => {
      try {
        const url = `${BASE_URL}ware-houses/team-member/?warehouse_id=${wareHouseId}`;
        const response = await requestInstance.get(url);
        setTeamMembers(response.data.results);
      } catch (error) {
        console.error('Failed to fetch team members', error);
      }
    };

    if (wareHouseId) {
      fetchTeamMembers();
    }
  }, [wareHouseId]);
  console.log("++")
  console.log(wareHouseId)

  const handleDeleteTeamMember = async (memberId) => {
    try {
      const url = `${BASE_URL}ware-houses/team-member/${memberId}/`;
      await requestInstance.delete(url);
      setTeamMembers(prevMembers => prevMembers.filter(member => member.id !== memberId));
      toast.success('Team member deleted successfully!');
    } catch (error) {
      console.error('Failed to delete team member', error);
      toast.error('Failed to delete team member.');
    }
  };

  return (
    <>
      <div className='ProductForm__header'>
        <h2 className='ProductForm__title'>Teams</h2>
        <button
          className='ProductForm__header__button ProductForm__header__button--save TeamList__button'
          onClick={() => {
            
            setCurrentForm('teamMemberForm');
          }}>
          Add Team member
        </button>
      </div>
      <div className='TeamList'>
        {teamMembers.map(member => (
          <div key={member.id} className='TeamList__member'>
            <img src={member.photo} alt={member.full_name} className='TeamList__member-photo' />
            <div className='TeamList__member-info'>
              <h3 className='TeamList__member-name'>{member.full_name}</h3>
              <p className='TeamList__member-position'>{member.position}</p>
            </div>
            <div className='TeamList__actions'>
              <img className='TeamList__icon' src={deleteImg} alt="delete" onClick={() => handleDeleteTeamMember(member.id)} />
              <img className='TeamList__icon' src={editImg} alt="edit" onClick={() => {
                setCurrentForm('teamMemberForm');
                setCurrentTeamMember(member.id);
              }} />
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default TeamList;