import logo from './images/logo1.png';
import search from './images/search.png';
import burgerElement from './images/burgerElement.png';
import emptyUser from './images/emptyUser.png';
import plane from './images/plane.png';
import bell from './images/bell.png';
import './Header.scss';
import cn from 'classnames'
import { LoginForm } from '../Login/LoginForm';
import { ForgotPasswordForm1 } from '../ForgotPaswordForms/ForgotPasswordForm1';
import { ForgotPasswordForm2 } from '../ForgotPaswordForms/ForgotPasswordForm2';
import { ForgotPasswordForm3 } from '../ForgotPaswordForms/ForgotPasswordForm3';
import { CompanyForm } from '../Registration/CompanyForm/CompanyForm'
import { UserForm } from '../Registration/UserForm/UserForm'
import { EmailConfirmationForm } from '../Registration/EmailConfirmationForm/EmailConfirmationForm'
import { useState, useEffect, useContext, useMemo, useRef } from 'react';
import { useUser } from '../../context/UserContext';
import {Link, useLocation, useNavigate} from 'react-router-dom'
import arrow from './images/arrow.png'
import {SearchContext} from '../../context/SearchContext'

export const Header = ({ isBlack = true }) => {
  const [isLoginFormVisible, setIsLoginFormVisible] = useState(false);
  const [isRegisterFormVisible, setIsRegisterFormVisible] = useState(false)
  const [isUserFormVisible, setIsUserFormVisible] = useState(false)
  const [isEmailConfirmationFormVisible, setIsEmailConfirmationFormVisible] = useState(false)
  const [isForgotPasswordForm1Visible, setIsForgotPasswordForm1Visible] = useState(false);
  const [isForgotPasswordForm2Visible, setIsForgotPasswordForm2Visible] = useState(false);
  const [isForgotPasswordForm3Visible, setIsForgotPasswordForm3Visible] = useState(false);
  const { user } = useUser() 
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { setSearchQuery } = useContext(SearchContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const userLockal = JSON.parse(localStorage.getItem('user'));

  const logout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('user');

    window.location.href = '/';
  };

  const allowedPages = useMemo(() => ['/products/'], []);

  const toggleSearch = () => {
    if (!isSearchOpen && !allowedPages.includes(location.pathname)) {
      navigate(allowedPages[0]);
    }
    setIsSearchOpen(!isSearchOpen);
  };
    useEffect(() => {
      if (!allowedPages.includes(location.pathname)) {
        setIsSearchOpen(false);
      }
    }, [allowedPages, location.pathname]);
    
  const toggleLoginForm = () => {
    setIsLoginFormVisible(!isLoginFormVisible);
  };
  const toggleCompanyForm = () => {
    setIsRegisterFormVisible(!isRegisterFormVisible);
  };
  const toggleForgotPassword1Form = () => {
    setIsForgotPasswordForm1Visible(!isForgotPasswordForm1Visible);
  };

  useEffect(() => {
    if (isRegisterFormVisible || isLoginFormVisible || isUserFormVisible || isEmailConfirmationFormVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isRegisterFormVisible, isLoginFormVisible, isUserFormVisible, isEmailConfirmationFormVisible]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);
  return (
    <>
    <header className={cn({ 'Header': true, 'Header__white': !isBlack })}>
      <div className='Header__logo-container'>
      <Link to='/'><img src={logo} alt='Logo' className={cn({ 'Header__logo': true, 'Header__logo__white': !isBlack })} /> </Link>
      </div>
      <nav className={cn({ 'Header__nav': true, 'Header__nav__white': !isBlack })}>
          {isSearchOpen ? (
        <div className="Header__search_container">
          <img onClick={toggleSearch} className="Header__nav__collapse_button" src={ arrow } alt='arrow'/>
              <input
                type="text"
                className="Header__nav__search_input"
                placeholder="Search..."
                onChange={(e) => setSearchQuery(e.target.value)} 
              />
        </div>
      ) : (
        // <>
        //   <span className='Header__nav__item Header__nav__item__first'><Link to='locations/'>Location</Link></span>
        //   <span>|</span>
        //   <span className='Header__nav__item'><Link to='products/'>Market Trade</Link></span>
        //   <span>|</span>
        //   <span className='Header__nav__item Header__nav__item__last'><Link to='ware-houses/'>Reseller</Link></span>
        //   <img className='Header__nav__search_icon' src={search} alt='search' onClick={toggleSearch} />
              // </>
              <>
              {userLockal?.company.type_of_business === 'brand' ? (
                <>
                  {/* <span className='Header__nav__item Header__nav__item__first'><Link to='locations/'>Location</Link></span>
                  <span>|</span> */}
                  <span className='Header__nav__item Header__nav__item__last'><Link to='ware-houses/'>Reseller</Link></span>
                  <img className='Header__nav__search_icon' src={search} alt='search' onClick={toggleSearch} />
                </>
              ) : (
                <>
                      <span className='Header__nav__item'><Link to='products/'> Search products/brands</Link></span>
                      {/* Market Trade */}
                  <img className='Header__nav__search_icon' src={search} alt='search' onClick={toggleSearch} />
                </>
              )}
            </>
      )}
      </nav>

      <div className='Header__block'>

      <div className='Header__actions'>
        {!user ? (
            <span className={cn({ 'Header__register': true, 'Header__register__white': !isBlack })}>
                <span onClick={toggleLoginForm}>Login</span> /
                <span onClick={toggleCompanyForm}> Register your brand</span>
                
            </span>
        ) : (
          <>
            <img src={bell} alt='notifications' className={cn({ 'Header__icon': true, 'Header__icon__white': isBlack })}/>
            <img src={plane} alt='messages' className={cn({ 'Header__icon': true, 'Header__icon__white': isBlack })}/>
          </>
        )}
        </div>
        
      <div className={cn({ 'Header__user': true, 'Header__user__white': !isBlack })}>
        <div className='Header__user__menu' onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <img src={burgerElement} alt='burger' />
          <img src={burgerElement} alt='burger' />
          <img src={burgerElement} alt='burger' />
            </div>
            <Link to='profile/'>
        <img
          className='Header__user__avatar'
          src={user?.user_photo || emptyUser}
          alt='User avatar'
              />
              </Link>
          </div>
          
          {isMenuOpen && (
            <div className="Header__dropdown" ref={menuRef}>
              <ul>
                <li onClick={() => setIsMenuOpen(false)}><Link to="/orders">My orders</Link></li>
                <li onClick={() => setIsMenuOpen(false)}><Link to="/basket">Basket</Link></li>
                <li onClick={() => setIsMenuOpen(false)}><Link to="/lead-list">Lead List</Link></li>
                <li onClick={logout}><Link to="/">Log out</Link></li> 
              </ul>
            </div>
          )}

      </div>
    </header>
    {isLoginFormVisible && (
        <div className="Overlay">
          <div className="Overlay__content" onClick={(e) => e.stopPropagation()}>
            <LoginForm
              setIsLoginFormVisible={setIsLoginFormVisible}
              setIsRegisterFormVisible={setIsRegisterFormVisible}
              setIsForgotPasswordForm1Visible={setIsForgotPasswordForm1Visible}
            />
       </div>
     </div>
      )}
      {isRegisterFormVisible && (
        <div className="Overlay">
          <div className="Overlay__content" onClick={(e) => e.stopPropagation()}
          >
            <CompanyForm
              setIsRegisterFormVisible={setIsRegisterFormVisible}
              setIsUserFormVisible={setIsUserFormVisible}
              setIsLoginFormVisible={setIsLoginFormVisible}
            />
       </div>
     </div>
      )}
      {isUserFormVisible && (
      <div className="Overlay">
        <div className="Overlay__content" onClick={(e) => e.stopPropagation()}
        >
            <UserForm
              setIsUserFormVisible={setIsUserFormVisible}
              setIsEmailConfirmationFormVisible={setIsEmailConfirmationFormVisible}
            />
        </div>
      </div>
  )
      }
      
      {isEmailConfirmationFormVisible && (
        <div className="Overlay">
          <div className="Overlay__content" onClick={(e) => e.stopPropagation()}
          >
            <EmailConfirmationForm
              setIsEmailConfirmationFormVisible={setIsEmailConfirmationFormVisible}
            />
          </div>
        </div>
      )
      }
      {isForgotPasswordForm1Visible && (
        <div className="Overlay">
          <div className="Overlay__content" onClick={(e) => e.stopPropagation()}>
            <ForgotPasswordForm1
              setIsForgotPasswordFormVisible={setIsForgotPasswordForm1Visible}
              setIsLoginFormVisible={setIsLoginFormVisible}
              setIsForgotPasswordForm2Visible={setIsForgotPasswordForm2Visible}
            />
          </div>
        </div>
      )}
      {isForgotPasswordForm2Visible && (
        <div className="Overlay">
          <div className="Overlay__content" onClick={(e) => e.stopPropagation()}>
            <ForgotPasswordForm2
              setIsForgotPasswordForm2Visible={setIsForgotPasswordForm2Visible}
              setIsForgotPasswordForm3Visible={setIsForgotPasswordForm3Visible}
            />
          </div>
        </div>
      )}
      {isForgotPasswordForm3Visible && (
        <div className="Overlay">
          <div className="Overlay__content" onClick={(e) => e.stopPropagation()}>
            <ForgotPasswordForm3
              setIsForgotPasswordForm3Visible={setIsForgotPasswordForm3Visible}
            />
          </div>
        </div>
      )}
      </>
  );
};
export default Header;